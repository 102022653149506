import './ItemMenu.css'
import React, { useContext } from 'react';
import House from '../../src/assets/house.png';
import Cursor from '../../src/assets/cursor.png';
import { Link } from 'react-router-dom';

import { AuthContext } from '../contexts/auth';

function ItemMenu({ areas }) {

    const { condId } = useContext(AuthContext)

    return (
        <div className="flex lg:flex-row gap-4 flex-col justify-content-center text-center">
            {

                areas.map((area) => (
                    <div className=" col text-center area-item" key={area._id}>

                        <Link style={{ textDecoration: 'none' }} to={`/${area.condId}/${area._id}/${area.name}`} >
                            <div className="circle">
                                <img className='item-icon' src={House} alt="icon" />
                            </div>
                            <span>{area.name}</span>
                        </Link>
                    </div>

                ))
            }

            <div className=" col text-center area-item">
                <Link style={{ textDecoration: 'none' }} to={`/${condId}/vistorias`} >
                    <div className="circle">
                        <img className='item-icon' src={Cursor} alt="icon" />
                    </div>
                    <span>Vistorias anteriores</span>

                </Link>
            </div>
        </div>



    );
}

export default ItemMenu;