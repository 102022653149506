// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-menu-areas {
    padding-top: 5rem;
    width: 100%;
    height: 100vh;
  
}

`, "",{"version":3,"sources":["webpack://./src/pages/user/UserMenuPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,aAAa;;AAEjB","sourcesContent":[".user-menu-areas {\r\n    padding-top: 5rem;\r\n    width: 100%;\r\n    height: 100vh;\r\n  \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
