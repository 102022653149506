// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    
}
input[type=number] {
   appearance: textfield;

}

`, "",{"version":3,"sources":["webpack://./src/components/ItemCpanelCond.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;;AAE5B;AACA;GAEG,qBAAqB;;AAExB","sourcesContent":["input[type=number]::-webkit-inner-spin-button { \r\n    -webkit-appearance: none;\r\n    \r\n}\r\ninput[type=number] { \r\n   -moz-appearance: textfield;\r\n   appearance: textfield;\r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
