import React, { useEffect, useState, useContext } from "react";
import { getVistoria } from "../services/api";
import { AuthContext } from "../contexts/auth";
import {
  AiOutlineClose,
  AiOutlineCheckCircle,
  AiOutlinePicture
} from "react-icons/ai";
import { FiVideo } from "react-icons/fi";

const VistoriaModal = ({ idVistoria, show, onClose }) => {
  const { condId } = useContext(AuthContext);

  const [data, setData] = useState({});
  const [itens, setItens] = useState([]);

  // Estados para expansão de imagem
  const [expandedImageSrc, setExpandedImageSrc] = useState("");
  const [showExpandedModal, setShowExpandedModal] = useState(false);

  useEffect(() => {
    if (show) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [show]);

  const fetchData = async () => {
    try {
      const response = await getVistoria(condId, idVistoria);
      setData(response.data);
      setItens(response.data.itens);
    } catch (error) {
      console.error("Erro ao buscar vistorias:", error);
    }
  };

  if (!show) return null;

  // Agrupar os itens por subárea
  const groupedItems = itens.reduce((acc, item) => {
    if (!acc[item.subAreaName]) {
      acc[item.subAreaName] = [];
    }
    acc[item.subAreaName].push(item);
    return acc;
  }, {});

  // Ordenar os itens dentro de cada subárea (priorizando os que possuem vencimento)
  Object.keys(groupedItems).forEach((key) => {
    groupedItems[key].sort((a, b) => {
      const hasVencA = a.vencimento ? 1 : 0;
      const hasVencB = b.vencimento ? 1 : 0;

      if (hasVencA !== hasVencB) {
        return hasVencB - hasVencA;
      }
      return a.name.localeCompare(b.name);
    });
  });

  // Ordenar as subáreas: primeiro as que possuem itens com vencimento
  const sortedGroupedItems = Object.entries(groupedItems)
    .sort(([subAreaA, itemsA], [subAreaB, itemsB]) => {
      const hasVencA = itemsA.some((item) => item.vencimento);
      const hasVencB = itemsB.some((item) => item.vencimento);

      if (hasVencA !== hasVencB) {
        return hasVencB - hasVencA;
      }
      return subAreaA.localeCompare(subAreaB);
    })
    .reduce((acc, [subAreaName, items]) => {
      acc[subAreaName] = items;
      return acc;
    }, {});

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  // Abre o modal de imagem expandida
  const openExpandedImage = (src) => {
    setExpandedImageSrc(src);
    setShowExpandedModal(true);
  };

  // Fecha o modal de imagem expandida
  const closeExpandedImage = () => {
    setExpandedImageSrc("");
    setShowExpandedModal(false);
  };

  return (
    <>
      {/* MODAL PRINCIPAL DA VISTORIA */}
      <div className="fixed inset-0 flex items-center justify-center z-[9999] py-10 bg-black bg-opacity-50 overflow-y-auto">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl max-h-full overflow-y-auto">
          {/* Cabeçalho */}
          <div className="border-b p-4 flex justify-between items-center sticky top-0 bg-white z-10">
            <h2 className="text-xl font-bold">Vistoria</h2>
            <button
              className="text-gray-500 hover:text-gray-800"
              onClick={handleClose}
            >
              <AiOutlineClose size={24} />
            </button>
          </div>

          {/* Conteúdo */}
          <div className="p-4">
            <div className="flex flex-col">
              <h5 className="mb-3 text-lg font-semibold">Dados da vistoria</h5>
              <div className="mb-2">
                <strong>Nome do morador:</strong> {data.nomeMorador}
              </div>
              <div className="mb-2">
                <strong>Apartamento:</strong> {data.numeroApartamento}
              </div>
              <div className="mb-2">
                <strong>Email do morador:</strong> {data.emailMorador}
              </div>
              <div className="mb-4">
                <strong>Observação geral:</strong> {data.observacoes}
              </div>

              {/* Exibição dos itens agrupados por subárea */}
              {Object.keys(sortedGroupedItems).map((area) => (
                <div key={area} className="mb-4">
                  <h6 className="pb-2 border-b font-medium text-base">{area}</h6>
                  {sortedGroupedItems[area].map((item) => (
                    <div
                      key={item._id}
                      className="flex justify-between border rounded p-4 mb-3"
                    >
                      <div>
                        <h6 className="mb-2 font-semibold text-base flex items-center">
                          {item.name}{" "}
                          <AiOutlineCheckCircle className="inline ml-2 text-green-500" />
                        </h6>
                        <div className="mb-2">
                          <strong>Área:</strong> {item.subAreaName}
                        </div>
                        <div className="mb-2">
                          <strong>Confere:</strong> {item.confere}
                        </div>
                        <div className="mb-2">
                          <strong>Observação do item:</strong> {item.obs}
                        </div>
                        <div>
                          <strong>Quantidade:</strong> {item.quantity} pçs.
                        </div>
                      </div>

                      {/* Exibição de arquivos (imagens/vídeos) */}
                      <div className="ml-4">
                        <strong>Imagens:</strong>
                        {item.FileBase64 !== "imagem não anexada" &&
                          item.FileBase64 ? (
                          <img
                            src={`data:image/**;base64,${item.FileBase64}`}
                            alt="imagem"
                            className="mt-2 w-32 h-32 object-cover rounded-md cursor-pointer"
                            onClick={() =>
                              openExpandedImage(
                                `data:image/**;base64,${item.FileBase64}`
                              )
                            }
                          />
                        ) : Array.isArray(item.files) && item.files.length > 0 ? (
                          item.files.map((file, index) => {
                            const isVideo =
                              file.includes(".mp4") ||
                              file.includes(".wav") ||
                              file.includes(".mkv") ||
                              file.includes(".mov") ||
                              file.includes(".webm");

                            return (
                              <div key={index} className="mt-2">
                                {isVideo ? (
                                  <video
                                    controls
                                    className="w-40 h-28 object-cover rounded-md"
                                    src={file}
                                  >
                                    <FiVideo className="text-blue-500" />
                                  </video>
                                ) : (
                                  <img
                                    src={file}
                                    alt="arquivo"
                                    className="w-32 h-32 object-cover rounded-md cursor-pointer"
                                    onClick={() => openExpandedImage(file)}
                                  />
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <span className="text-gray-500 flex items-center gap-1 mt-2">
                            Imagem não anexada <AiOutlinePicture />
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          {/* Rodapé */}
          <div className="border-t p-4 flex justify-end sticky bottom-0 bg-white z-10">
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              onClick={handleClose}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>

      {/* MODAL (OVERLAY) PARA EXPANDIR A IMAGEM */}
      {showExpandedModal && expandedImageSrc && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-[10000] flex items-center justify-center">
          <div className="relative">
            <button
              className="absolute top-3 right-3 text-white bg-black bg-opacity-40 rounded-full p-1 hover:bg-opacity-80"
              onClick={closeExpandedImage}
            >
              <AiOutlineClose size={28} />
            </button>
            <img
              src={expandedImageSrc}
              alt="Imagem Expandida"
              className="max-h-screen max-w-screen object-contain"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VistoriaModal;
