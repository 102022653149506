// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: baseline;
    margin-top: 20px;
    flex-wrap: wrap;
}

.box-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
}

.item-pagination {
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    color: #05004e;
    background-color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.item-pagination p {
    font-size: 16px;
    margin-bottom: 0px;
}

.item-pagination:hover {
    background-color: #b0c8e3;
    transition: 0.3s;
}

.item-pagination.selected {
    background-color: #3788e5;
    color: white;
    transition: 0.3s;
}

.previous {
    background: #f3f3f3;
}

.next {
    background: #f3f3f3;
}
`, "",{"version":3,"sources":["webpack://./src/components/paginations.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IAEf,cAAc;IACd,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".container-pagination {\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: baseline;\r\n    margin-top: 20px;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.box-pagination {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    gap: 3px;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.item-pagination {\r\n    padding: 4px;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    transition: 0.7s;\r\n    color: #05004e;\r\n    background-color: white;\r\n    width: 30px;\r\n    height: 30px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    transition: 0.3s;\r\n}\r\n\r\n.item-pagination p {\r\n    font-size: 16px;\r\n    margin-bottom: 0px;\r\n}\r\n\r\n.item-pagination:hover {\r\n    background-color: #b0c8e3;\r\n    transition: 0.3s;\r\n}\r\n\r\n.item-pagination.selected {\r\n    background-color: #3788e5;\r\n    color: white;\r\n    transition: 0.3s;\r\n}\r\n\r\n.previous {\r\n    background: #f3f3f3;\r\n}\r\n\r\n.next {\r\n    background: #f3f3f3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
