import React, { useState, useContext, useEffect } from 'react';
import TopNavbar from './../../components/TopNavbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import { getCondominios, createCondominio } from '../../services/api';
import { AuthContext } from '../../contexts/auth';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/pt-br';

import './cpanel.css'
import { Link, Navigate } from 'react-router-dom';
import ItemCpanelCond from '../../components/ItemCpanelCond';


function Cpanel() {

    moment().locale('pt-br')

    const { condId } = useContext(AuthContext)

    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);

    const [Condominios, setCondominios] = useState([])

    const handleNewCond = async (name, cnpj, email, endereco, telefone, responsavel) => {
        try {
            console.log('New cond', name, cnpj, email, endereco, telefone, responsavel)
            await createCondominio(name, cnpj, email, endereco, telefone, responsavel)
            loadData()
        } catch (err) {
            console.error(err)
        }

    }


    const loadData = async () => {
        try {
            setLoading(true)
            const responseCondominios = await getCondominios()
            setCondominios(responseCondominios.data)
            setLoading(false)
        } catch (err) {
            console.error(err);
            setLoadingError(true)
        }
    }

    useEffect(() => {
        (async () => await loadData())()
    }, [])


    if (loadingError) {
        return (
            <div className='loading'>
                Erro ao carregar itens da vistoria. <Link to={`/${condId}/`} >Voltar</Link>
            </div>
        )
    }

    if (loading) {
        return (
            <div className="loading">
                carregando...
            </div>
        )
    }

    return (
        <div className="user-menu">
            <TopNavbar />
            <div className="user-menu-areas">
                <div className="container px-4 py-5" id="hanging-icons">
                    <h2 className="pb-2 border-bottom">Condominios</h2>
                    < ItemCpanelCond
                        onNewCond={handleNewCond}
                    />
                    <div className='container cpanel-itens'>
                        <div className='row'>
                            {/* <Condominios /> */}

                            {Condominios.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((condominio) => (
                                <div className='condominios' key={condominio._id}>
                                    <div>
                                        <div>{condominio.name}</div>
                                        <div>CNPJ: {condominio.cnpj}</div>
                                        <div>
                                            <Moment format='DD/MM/YYYY, h:mm:ss '>{condominio.createdAt}</Moment>
                                        </div>
                                    </div>
                                    <Link className='btn' key={condominio._id} to={`/cpanel/condominios/${condominio._id}/${condominio.name}`}>
                                        ver
                                    </Link>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cpanel; //;
