import React, { useState, useContext, useEffect } from "react";
import TopNavbar from "./../../components/TopNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { getVistorias } from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/pt-br";

import Aside from "../../components/Aside/Aside";
import "../../components/Loading.css";
import "./vistoriasPage.css";
import VistoriaModal from "../../components/VistoriaModal";
import Pagination from "../../components/Pagination";

function VistoriaPage() {
  moment.locale("pt-br");
  const { condId } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  // Estados principais
  const [vistorias, setVistorias] = useState([]);
  const [filteredVistorias, setFilteredVistorias] = useState([]);

  // Filtros
  const [filters, setFilters] = useState({
    category: "",
    subcategory: "",
  });

  // Estado real da data (usado no backend)
  const [dateFilter, setDateFilter] = useState("");
  // Estado temporário para o <input type="date">
  const [tempDateFilter, setTempDateFilter] = useState("");

  // Modal de vistoria
  const [showVistoriaModal, setShowVistoriaModal] = useState(false);
  const [selectedVistoriaId, setSelectedVistoriaId] = useState(null);

  // Paginação
  const [selected, setSelected] = useState("1");
  const [total, setTotal] = useState("");

  // Mostrar ou não a sidebar
  const [showAside, setShowAside] = useState(true);

  const handleShowVistoriaModal = (id) => {
    setSelectedVistoriaId(id);
    setShowVistoriaModal(true);
  };

  const handleCloseVistoriaModal = () => {
    setShowVistoriaModal(false);
  };

  // Quando o usuário clica em algo no Aside, ele define os filtros
  // e chamamos handleFilterSelection para ocultar a sidebar
  const handleFilterSelection = () => {
    setShowAside(false);
  };

  // Função que busca as vistorias no backend
  const loadData = async (page) => {
    try {
      setLoading(true);

      console.log("condId:", condId);

      // Chama a API, passando condId, página, limite e Filtros (incluindo dateFilter)
      const response = await getVistorias(condId, page, "10", {
        category: filters.category,
        subcategory: filters.subcategory,
        date: dateFilter, // É O dateFilter "oficial"
      });

      setVistorias(response.data.vistorias);
      setFilteredVistorias(response.data.vistorias);
      setTotal(response.data.total);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoadingError(true);
    }
  };

  // Aplica os filtros, resetando a página para "1"
  const applyFilters = () => {
    setSelected("1");
  };

  const handleVoltar  = () => {
    setShowAside(true)
    clearFilters()
  };

  // Função chamada ao clicar em "OK" do campo de data
  const handleApplyDateFilter = () => {
    // Só nesse momento atualizamos a data "real"
    setDateFilter(tempDateFilter);
    // E chamamos applyFilters para resetar a página e disparar a busca
    applyFilters();
  };

  // Limpa filtros e data
  const clearFilters = () => {
    setFilters({ category: "", subcategory: "" });
    setDateFilter("");
    setTempDateFilter("");
    setSelected("1");
  };

  // Efeito para recarregar dados quando mudar:
  // - a página atual (selected),
  // - ou os filtros (filters),
  // - ou a data (dateFilter) REAL.
  useEffect(() => {
    loadData(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, filters, dateFilter]);

  // Caso queira que sempre que filters ou dateFilter mudem, volte pra página 1
  useEffect(() => {
    setSelected("1");
  }, [filters, dateFilter]);

  // Renderizações condicionais
  if (loadingError) {
    return (
      <div className="loading">
        Erro ao carregar vistorias anteriores.{" "}
        <Link to={`/${condId}/`}>Voltar</Link>
      </div>
    );
  }

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (showAside) {
    return (
      <div className="user-menu">
        <TopNavbar />
        <div className="">
          <Aside
            filters={filters}
            setFilters={(newFilters) => {
              setFilters(newFilters);
              handleFilterSelection();
            }}
            applyFilters={applyFilters} // se quiser manter pra "Itens a Vencer"
            clearFilters={clearFilters}
          />
        </div>
      </div>
    );
  }

  if (filteredVistorias.length === 0) {
    return (
      <div className="user-menu">
        <TopNavbar />
        <div className="user-menu-areas">
          <div style={styles.pageContainer}>
            <div style={styles.mainContent}>
              <div className="container px-4 py-5 text-center">
                <h2 className="font-bold mb-4">Nenhuma vistoria encontrada</h2>
                <p>
                  Por favor, selecione um componente na Sidebar ou ajuste os filtros para visualizar
                  os itens disponíveis.
                </p>
                <div className="flex justify-center items-center gap-4 mt-4">
                  <button
                    className="p-2 bg-blue-500 text-white rounded-lg font-bold"
                    onClick={() => handleVoltar()}
                  >
                    Voltar
                  </button>
                  <div className="flex items-center gap-2">
                    <label htmlFor="search-date" className="font-bold">
                      Data:
                    </label>
                    <input
                      id="search-date"
                      type="date"
                      className="p-2 border rounded-md"
                      // Mostramos e alteramos o estado TEMPORÁRIO
                      value={tempDateFilter}
                      onChange={(e) => setTempDateFilter(e.target.value)}
                    />
                    <button
                      className="p-2 bg-blue-500 text-white rounded-lg font-bold"
                      onClick={handleApplyDateFilter}
                    >
                      Procurar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Se temos vistorias, renderizamos normalmente
  return (
    <div className="user-menu">
      <TopNavbar />
      <div className="user-menu-areas">
        <div style={styles.pageContainer}>
          <div style={styles.mainContent}>
            <div className="container px-4 py-5" id="hanging-icons">
              <div className="flex flex-col md:flex-row justify-between w-full items-center pb-4 border-b">
                <div className="flex gap-4">
                  <button
                    className="p-2 bg-blue-500 text-white rounded-lg font-bold"
                    onClick={() => handleVoltar()}
                  >
                    Voltar
                  </button>
                  <div className="flex items-center">
                    <input
                      type="date"
                      className="p-2 border rounded-lg"
                      value={tempDateFilter}
                      onChange={(e) => setTempDateFilter(e.target.value)}
                    />
                    <button
                      className="p-2 bg-green-500 text-white rounded-lg font-bold ml-2"
                      onClick={handleApplyDateFilter}
                    >
                      OK
                    </button>
                  </div>
                </div>
                <h2 className="font-bold mt-4">Vistorias anteriores</h2>
              </div>
              <div className="container cpanel-itens">
                <div className="row flex gap-4">
                  {filteredVistorias
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .map((vistoria) => (
                      <div
                        className="flex p-3 rounded-md border flex-col lg:flex-row justify-between gap-4 shadow-md"
                        key={vistoria._id}
                      >
                        <div>
                          <div className="font-bold">{vistoria.areaName}</div>
                          <div>
                            <Moment format="DD/MM/YYYY, h:mm:ss">
                              {vistoria.createdAt}
                            </Moment>
                          </div>
                        </div>
                        <Button
                          onClick={() => setShowVistoriaModal(true) || setSelectedVistoriaId(vistoria._id)}
                          className="rounded-lg"
                        >
                          Ver
                        </Button>
                      </div>
                    ))}

                  <div className="flex items-center gap-4">
                    <Pagination
                      selected={selected}
                      total={total}
                      setSelected={setSelected}
                      numberForPage="10"
                    />
                    <span className="font-bold">
                      Total de registros: {total}
                    </span>
                  </div>

                  <VistoriaModal
                    idVistoria={selectedVistoriaId}
                    show={showVistoriaModal}
                    onClose={handleCloseVistoriaModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
}

const styles = {
  pageContainer: {
    display: "flex",
    height: "100vh",
  },
  mainContent: {
    flex: 1,
    padding: "20px",
    overflowY: "auto",
  },
};

export default VistoriaPage;
