import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillHome, AiOutlineSwap } from 'react-icons/ai'; // Ícones existentes
import { FiMenu, FiX } from 'react-icons/fi'; // Ícones de menu e fechar
import Logo from '../assets/logo.png';
import { AuthContext } from '../contexts/auth';

function TopNavbar() {
  const { user, condId, logout } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para o menu

  const handleLogout = () => {
    logout();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const renderLoginButton = () => {
    return user ? (
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        onClick={handleLogout}
      >
        Sair
      </button>
    ) : null;
  };

  return (
    <nav className="bg-white text-black shadow-md">
      <div className="container mx-auto flex items-center justify-between px-4 py-3">
        {/* Ícone de Menu Hambúrguer para Mobile */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-2xl focus:outline-none" aria-label="Menu">
            {isMenuOpen ? <FiX /> : <FiMenu />}
          </button>
        </div>

        {/* Botão "Trocar Condomínio" no Canto Esquerdo */}
        <div className="hidden md:flex items-center">
          {user && (
            <button
              className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            >
              <AiOutlineSwap className="mr-2" size={20} /> {/* Ícone de troca */}
              <Link to={`/condlist`}>
                Trocar Condomínio
              </Link>
            </button>
          )}
        </div>

        {/* Logo Centralizada */}
        <div className="flex flex-col items-center justify-center">
          <img src={Logo} alt="Logo Aquila" className="w-16 h-16" />
          <p className='text-blue-800 font-medium text-xl'>Aquila Vistoria</p>
        </div>

        {/* Botões à Direita */}
        <div className="hidden md:flex flex-col md:flex-row gap-4 items-center space-x-4">
          {/* Botão "Ir para áreas" */}
          {user && (
            <Link
              to={`/${condId}`}
              className="flex items-center bg-blue-500 text-white px-4 py-2 capitalize rounded hover:bg-blue-600 transition"
            >
              <AiFillHome className="mr-2" size={20} /> {/* Ícone de casa */}
              áreas
            </Link>
          )}

          {/* Botão de Logout */}
          {renderLoginButton()}
        </div>
      </div>

      {/* Menu Mobile */}
      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-md transition-all duration-300">
          <div className="px-4 pt-2 pb-4 flex flex-col space-y-2">
            {/* Botão "Trocar Condomínio" */}
            {user && (
              <Link
                to={`/condlist`}
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                onClick={() => setIsMenuOpen(false)} // Fecha o menu ao clicar
              >
                <AiOutlineSwap className="mr-2" size={20} />
                Trocar Condomínio
              </Link>
            )}

            {/* Botão "Ir para áreas" */}
            {user && (
              <Link
                to={`/${condId}`}
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                onClick={() => setIsMenuOpen(false)}
              >
                <AiFillHome className="mr-2" size={20} />
                áreas
              </Link>
            )}

            {/* Botão de Logout */}
            {user && (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                onClick={() => { handleLogout(); setIsMenuOpen(false); }}
              >
                Sair
              </button>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}

export default TopNavbar;
