import React, { useState, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import { createSession } from '../../services/api';
import TopNavbar from './../../components/TopNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/logo.png';
import './LoginPage.css';

function LoginPage() {
    const { authenticated, user, login } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false); // Controle para garantir que o login foi bem-sucedido

    useEffect(() => {
        // Só redireciona se o login realmente foi confirmado
        if (authenticated && loginSuccess && user?.id) {
            console.log("Usuário autenticado. Redirecionando para condlist...");
        }
    }, [authenticated, loginSuccess, user]);

    if (authenticated && loginSuccess && user?.id) {
        return <Navigate to='/condlist' replace />;
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoggingIn(true);

        try {
            await login(email, password);
            const response = await createSession(email, password);
            console.log('Login bem-sucedido:', response.data);

            // Se chegou até aqui, o login foi bem-sucedido
            setLoginSuccess(true);
        } catch (error) {
            console.error("Erro no login:", error);
        } finally {
            setIsLoggingIn(false);
        }
    };

    return (
        <div className="login">
            <TopNavbar />
            <div className="form-area">
                <div className="mask">
                    <div className="container col-xl-10 col-xxl-8 px-4 py-5">
                        <div className="row align-items-center g-lg-5 py-5">
                            <div className="col-md-10 mx-auto col-lg-5">
                                <form className="py-3 px-4">
                                    <div className="form-floating mb-3 logo-content">
                                        <img className="logo" src={Logo} alt="Logo" />
                                        <h3>Aquila Vistorias</h3>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="floatingInput"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                        <label htmlFor="floatingInput">Login</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="floatingPassword"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                        <label htmlFor="floatingPassword">Senha</label>
                                    </div>
                                    <button
                                        className="w-100 btn btn-lg btn-primary"
                                        onClick={handleLogin}
                                        disabled={isLoggingIn} // Evita múltiplos cliques
                                    >
                                        {isLoggingIn ? "Entrando..." : "Entrar"}
                                    </button>
                                    <hr className="my-4" />
                                </form>
                            </div>
                            <div className="col-lg-5 text-center "></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
