import axios from "axios";

export const api = axios.create({
    // baseURL: 'http://44.211.60.51:5000',
     baseURL: "https://back.aquilavistorias.com.br" //PROD
  // baseURL: "http://localhost:5000", //DEV
    //  baseURL: "https://testevistoria-production.up.railway.app" //TESTE 
});

export const createSession = async (email, password) => {
    return api.post("/sessions", { email, password });
};

export const getCondominios = async () => {
    let url = `/condominios/`;

    return api.get(url);
};

export const getCondominiosByUser = async (userId) => {
    let url = `/users/${userId}/condominios`;

    return api.get(url);
};

export const getCondominio = async (condId) => {
    let url = `/condominios/${condId}`;

    return api.get(url);
};

export const createCondominio = async (
    name,
    cnpj,
    email,
    endereco,
    telefone,
    responsavel
) => {
    const url = `/condominios/`;

    return api.post(url, {
        name,
        cnpj,
        email,
        endereco,
        telefone,
        responsavel
    });
};

export const getRegulamento = async (condId) => {
    let url = `/condominios/${condId}/regulamento/`;

    return api.get(url);
};

export const uploadRegulamento = async (condId, file) => {
    const formData = new FormData();
    formData.append("regulamento", file);
    formData.append("condId", condId);

    const url = `/condominios/${condId}/`;

    console.log(file);
    console.log(formData);

    return api.post(url, formData);
};

export const destroyRegulamento = async (condId, id) => {
    const url = `/condominios/${condId}/regulamento/${id}`;

    return api.delete(url, { condId, id });
};

export const getUsers = async (condId) => {
    const url = `/condominios/${condId}/users`;

    return api.get(url);
};

export const createUser = async (
    name,
    cpf,
    email,
    cargo,
    whatsapp,
    password,
    condId,
    role
) => {
    const url = `/condominios/${condId}/users/`;

    return api.post(url, { name, cpf, email, cargo,whatsapp, password, condId, role });
};

export const destroyUser = async (condId, id) => {
    const url = `/condominios/${condId}/users/${id}`;

    return api.delete(url, { condId, id });
};

export const getAreas = async (condId) => {
    const url = `/condominios/${condId}/areas`;

    return api.get(url);
};

export const createArea = async (name, condId) => {
    const url = `/condominios/${condId}/areas`;

    return api.post(url, { name, condId });
};

export const destroyArea = async (condId, areaId) => {
    const url = `/condominios/${condId}/areas/${areaId}`;

    return api.delete(url, { condId, areaId });
};

export const getItens = async (condId, areaId) => {
    const url = `/condominios/${condId}/areas/${areaId}/itens`;

    return api.get(url);
};

export const createItens = async (condId, areaId, subAreaName, name) => {
    const url = `/condominios/${condId}/areas/${areaId}/itens`;

    return api.post(url, { name, subAreaName });
};

export const deleteItens = async (condId, areaId, subAreaName, id) => {
    const encodedSubAreaName = encodeURIComponent(subAreaName);
    const url = `/condominios/${condId}/areas/${areaId}/subareas/${encodedSubAreaName}/itens/${id}`;
    console.log(url);

    return api.delete(url);
};

export const getSubAreas = async (condId, areaId) => {
    const url = `/condominios/${condId}/areas/${areaId}/subareas/`;

    return api.get(url);
};

export const createSubArea = async (condId, areaId, subAreaName) => {
    const url = `/condominios/${condId}/areas/${areaId}/subareas/`;

    return api.post(url, { condId, areaId, subAreaName });
};

export const destroySubArea = async (condId, areaId, subAreaName, id) => {
    const url = `/condominios/${condId}/areas/${areaId}/subareas/${subAreaName}/${id}`;

    return api.delete(url);
};

export const createVistoria = async (condId, areaId, area_name, formData) => {
    const url = `/condominios/${condId}/areas/${areaId}/${area_name}/vistorias`;

    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };

    return api.post(url, formData, config);
};

// services/api.js (ou onde estiver seu serviço de API)
export const getVistorias = async (condId, page, limit, filters) => {
    // Desestruture os filtros
    const { category, subcategory, date } = filters || {};
  
    // Monta a query string via URLSearchParams (boa prática para montar query)
    const params = new URLSearchParams();
  
    // Paginação
    if (page) params.set("page", page);
    if (limit) params.set("limit", limit);
  
    // Filtros
    if (category) params.set("category", category);
    if (subcategory) params.set("subcategory", subcategory);
    if (date) params.set("date", date);
    console.log("filters:", filters)
  
    const url = `/condominios/${condId}/vistorias?${params.toString()}`;
    return api.get(url);
  };
  

export const getVistoria = async (condId, id) => {
    const url = `/condominios/${condId}/vistorias/${id}`;

    return api.get(url);
};


  
export const getVencimentos = async (condId) => {
    const url = `/condominios/${condId}/getvencimentos`;
    return api.get(url)
}
