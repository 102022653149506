import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./../../components/TopNavbar";
import { getCondominiosByUser } from "../../services/api";
import { AuthContext } from "../../contexts/auth";

function SelectCondominiumPage() {
    const navigate = useNavigate();
    const { user, selectCondo } = useContext(AuthContext);
    const [condominiums, setCondominiums] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchCondominios() {
            if (!user) {
                console.warn("Usuário não autenticado. Redirecionando para login...");
                navigate(`/`);
                return;
            }

            try {
                setLoading(true);
                setError(null);

                const response = await getCondominiosByUser(user.id);
                setCondominiums(response.data);
            } catch (err) {
                console.error("Erro ao buscar condomínios:", err);

                if (err.response && err.response.status === 401) {
                    console.warn("Erro 401 detectado. Redirecionando para login...");
                    navigate("/", { replace: true }); // `replace: true` evita que o usuário volte à página anterior
                } else {
                    setError("Ocorreu um erro ao buscar os condomínios.");
                }
            } finally {
                setLoading(false);
            }
        }

        if (user?.id) {
            fetchCondominios();
        }
    }, [user, navigate]);

    const handleCondoClick = (condId) => {
        selectCondo(condId);
        navigate(`/${condId}`);
    };

    if (loading) {
        return <div className="p-4">Carregando condomínios...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-500">{error}</div>;
    }

    return (
        <div>
            <TopNavbar />
            <div className="md:mx-[150px] p-4 flex flex-col justify-center items-center md:items-baseline md:justify-start mt-8">
                <h2 className="text-xl font-bold ">Meus Condomínios</h2>
                {condominiums.length === 0 ? (
                    <p>Você não possui condomínios cadastrados.</p>
                ) : (
                    <ul className="flex items-center justify-center flex-col gap-4 w-auto h-full mt-8">
                        {condominiums.map((cond) => (
                            <li
                                key={cond.id}
                                className="border-2 border-blue-400 p-4 w-[300px] h-full rounded-full flex items-center justify-center flex-col hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCondoClick(cond._id)}
                            >
                                <strong>{cond.name}</strong>
                                <div className="text-sm text-gray-600">{cond.address}</div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default SelectCondominiumPage;
