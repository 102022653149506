// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/eagle-login.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.form-area {
    width: 100%;
    height: 100vh;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) ;
    background-repeat: no-repeat;
    background-size: cover;
    
  
}

.form-area .logo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-area .logo{
    height: 5rem !important;
}


.form-area .mask {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgb(214, 214, 214) , rgba(255, 255, 255, 0.196));
}

.form-area .form-image{
    height: 20rem;
}

.form-area .cotainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

form {
    border-radius: 0%;
    background-color: rgb(255, 255, 255);
}

h3 {
    color: #1078ba !important;
}

form input, textarea{
    border-radius: 0% !important;
    background-color: rgba(255, 255, 255, 0) !important;
    border: solid 1px #1078ba !important;
}

form input::placeholder {
    color: #1078ba !important;
}

form button {
    border-radius: 0% !important;
}

`, "",{"version":3,"sources":["webpack://./src/pages/login/LoginPage.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,aAAa;IACb,0DAAsD;IACtD,4BAA4B;IAC5B,sBAAsB;;;AAG1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;AAC3B;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,4FAA4F;AAChG;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,mDAAmD;IACnD,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":["\r\n.form-area {\r\n    width: 100%;\r\n    height: 100vh;\r\n    background-image: url('../../assets/eagle-login.jpg') ;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    \r\n  \r\n}\r\n\r\n.form-area .logo-content {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.form-area .logo{\r\n    height: 5rem !important;\r\n}\r\n\r\n\r\n.form-area .mask {\r\n    width: 100%;\r\n    height: 100%;\r\n    background-image: linear-gradient(to right, rgb(214, 214, 214) , rgba(255, 255, 255, 0.196));\r\n}\r\n\r\n.form-area .form-image{\r\n    height: 20rem;\r\n}\r\n\r\n.form-area .cotainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\nform {\r\n    border-radius: 0%;\r\n    background-color: rgb(255, 255, 255);\r\n}\r\n\r\nh3 {\r\n    color: #1078ba !important;\r\n}\r\n\r\nform input, textarea{\r\n    border-radius: 0% !important;\r\n    background-color: rgba(255, 255, 255, 0) !important;\r\n    border: solid 1px #1078ba !important;\r\n}\r\n\r\nform input::placeholder {\r\n    color: #1078ba !important;\r\n}\r\n\r\nform button {\r\n    border-radius: 0% !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
