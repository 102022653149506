import './ItemVistoria.css'
import React, { useState, useEffect, useContext } from 'react'
import { getSubAreas } from '../services/api'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FormSelect from 'react-bootstrap/esm/FormSelect';
import { AuthContext } from '../contexts/auth';
import { useParams } from 'react-router-dom';


// const condId = "63cfef1490af8b72c706fe5f"
// const areaId = "63d17115a3b21f3c82cbbdde"


function ItemVistoria({ onNewItem }) {

    const { condId } = useContext(AuthContext)
    const { areaId } = useParams()

    const [newItemName, setNewItemName] = useState('')
    const [newItemSubArea, setNewItemSubArea] = useState('')
    const [subAreas, setSubAreas] = useState([])



    const loadData = async () => {
        const response = await getSubAreas(condId, areaId)

        setSubAreas(response.data)
    }

    useEffect(() => {
        (async () => await loadData())()
    }, [])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <div className='row'>

            <Button variant="primary" onClick={handleShow}>
                Adicionar um novo item
            </Button>



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar novo item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nome do Item:</Form.Label>
                            <Form.Control
                                type="text"
                                value={newItemName}
                                onChange={(e) => setNewItemName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Area do item:</label>
                            <FormSelect
                                className="form-select"
                                aria-label="Default select example"
                                value={newItemSubArea}
                                onChange={(e) => setNewItemSubArea(e.target.value)}
                            >
                                <option checked>selecione a área</option>
                                {
                                    subAreas.map((subArea => (
                                        <option
                                            key={subArea.subAreaName}
                                            value={subArea.subAreaName}
                                        >
                                            {subArea.subAreaName}
                                        </option>
                                    )))
                                }
                            </FormSelect>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        fechar
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            onNewItem(newItemName, newItemSubArea)}>
                        Adicionar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>




    );
}

export default ItemVistoria;