import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css'
import AreaVistoriaPage from './pages/Area/AreaVistoriaPage';
import LoginPage from './pages/login/LoginPage';
import UserMenuPage from './pages/user/UserMenuPage';
import Cpanel from './pages/cpanel/cpanel';
import CondList from './pages/condList/condList'
import CondCpanel from './pages/condCpanel/condCpanel';
import QrCode from './pages/qrcode/QrCode'
import { AuthProvider, AuthContext } from './contexts/auth';
import React, { useContext } from 'react';
import VistoriasPage from './pages/vistorias/vistoriasPage';

function App() {

  const Private = ({ children }) => {
    const { authenticated, loading, user } = useContext(AuthContext)


    if (loading) {
      return <div className="loading">Carregando...</div>
    }

    if (!authenticated) {
      return <Navigate to='/' />
    }


    return children;
  }

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route exact path='/' element={<LoginPage />} />
            <Route exact path="/condlist" element={<Private><CondList /></Private>} />
            <Route exact path='/:condId' element={<Private><UserMenuPage /></Private>} />
            <Route exact path='/:condId/:areaId/:area_name' element={<Private><AreaVistoriaPage /></Private>} />
            <Route exact path='/:condId/vistorias' element={<Private><VistoriasPage /></Private>} />
            <Route exact path='/cpanel' element={<Private role='admin'><Cpanel /></Private>} />
            <Route exact path='/cpanel/condominios/:id/:cond_name/' element={<Private role='admin'><CondCpanel /></Private>} />
            {/* <Route exact path='/qrcode' element={<QrCode/>}/> */}
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );

}

export default App;
