import React, { useEffect, useState } from 'react';
// import io from 'socket.io-client';

// Conecta ao backend (ajuste a URL se necessário)
// const socket = io('http://localhost:3000');

function App() {
  const [qr, setQr] = useState('');

//   useEffect(() => {
//     // Quando o evento 'qr' for recebido, atualiza o estado com a string base64
//     socket.on('qr', (base64Qr) => {
//       setQr(base64Qr);
//     });

//     // Limpeza: remove o listener ao desmontar o componente
//     return () => {
//       socket.off('qr');
//     };
//   }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>QR Code do Venom Bot</h1>
      {qr ? (
        <img
          src={`data:image/png;base64,${qr}`}
          alt="QR Code"
          style={{ maxWidth: '300px' }}
        />
      ) : (
        <p>Aguardando QR Code...</p>
      )}
    </div>
  );
}

export default App;
