import React, { createContext, useEffect, useState } from "react";
import { api, createSession } from "../services/api";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [condId, setCondId] = useState(null); // Adicionado para salvar o condId selecionado

    useEffect(() => {
        const user = localStorage.getItem("user");
        const token = localStorage.getItem("token");
        const savedCondId = localStorage.getItem("condId"); // Recupera o condId salvo

        if (user && token) {
            setUser(JSON.parse(user));
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }

        if (savedCondId) {
            setCondId(savedCondId); // Restaura o condId no contexto
        }

        setLoading(false);
    }, []);

    const login = async (email, password) => {
        const response = await createSession(email, password);

        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", response.data.token);

        api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

        setUser(response.data.user);

        if (response.data.user.role === "admin") {
            return navigate("/cpanel");
        } else {
            navigate(`/condlist`);
        }
    };

    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("condId"); // Limpa o condId ao sair

        api.defaults.headers.Authorization = null;
        setUser(null);
        setCondId(null); // Reseta o condId no estado
        navigate("/");
    };

    const selectCondo = (id) => {
        setCondId(id); // Atualiza o condId no estado
        localStorage.setItem("condId", id); // Salva no localStorage para persistência
    };

    return (
        <AuthContext.Provider
            value={{
                authenticated: !!user,
                user,
                condId, // Expondo o condId no contexto
                loading,
                login,
                logout,
                selectCondo, // Expondo o método selectCondo no contexto
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
