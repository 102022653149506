import './ItemVistoria.css'
import React, { useState, useEffect, useContext } from 'react'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';



// const condId = "63cfef1490af8b72c706fe5f"
// const areaId = "63d17115a3b21f3c82cbbdde"


function CreateAreaItem({ onNewArea }) {


    const [name, setName] = useState('')



    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <div className='row'>

            <Button variant="primary" onClick={handleShow}>
                Criar nova área
            </Button>



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar nova área</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nome da área:</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        fechar
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            onNewArea(name)}>
                        Adicionar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>




    );
}

export default CreateAreaItem;