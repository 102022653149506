// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
    border: 5px solid #1078ba;
    border-radius: 5px;
    min-width: 300px;
    max-width: 300px;

    text-align: left;
}

.delete-item {
    width: 2.5rem;
    border: 1px solid #1078ba;
    font-size: x-large;
    border-radius: 100% !important;
}

.button-item {
    background-color: #1078ba;
    padding: 0.5rem;
    border: none !important;
    color: #fff
}

 input[type='text'], input[type='number'], select, textarea{
    border-radius: 0% !important;
    background-color: rgba(255, 255, 255, 0) !important;
    border: solid 1px #1078ba !important;
}

 input[type='radio']{
    background-color: #fff !important;
    border: solid 1px #1078ba !important;
    margin-right: 1.1rem;
    border-radius: 100% !important;
}

 input[type='radio']:checked{
    background-color: #1078ba !important;
}

 input::placeholder {
    color: #1078ba !important;
    opacity: .7 !important;
}

 button {
    border-radius: 0% !important;
}

#formBasicPassword {
    display: flex,
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/ItemVistoria.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;;IAEhB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,uBAAuB;IACvB;AACJ;;CAEC;IACG,4BAA4B;IAC5B,mDAAmD;IACnD,oCAAoC;AACxC;;CAEC;IACG,iCAAiC;IACjC,oCAAoC;IACpC,oBAAoB;IACpB,8BAA8B;AAClC;;CAEC;IACG,oCAAoC;AACxC;;CAEC;IACG,yBAAyB;IACzB,sBAAsB;AAC1B;;CAEC;IACG,4BAA4B;AAChC;;AAEA;IACI;EACF","sourcesContent":[".item {\r\n    border: 5px solid #1078ba;\r\n    border-radius: 5px;\r\n    min-width: 300px;\r\n    max-width: 300px;\r\n\r\n    text-align: left;\r\n}\r\n\r\n.delete-item {\r\n    width: 2.5rem;\r\n    border: 1px solid #1078ba;\r\n    font-size: x-large;\r\n    border-radius: 100% !important;\r\n}\r\n\r\n.button-item {\r\n    background-color: #1078ba;\r\n    padding: 0.5rem;\r\n    border: none !important;\r\n    color: #fff\r\n}\r\n\r\n input[type='text'], input[type='number'], select, textarea{\r\n    border-radius: 0% !important;\r\n    background-color: rgba(255, 255, 255, 0) !important;\r\n    border: solid 1px #1078ba !important;\r\n}\r\n\r\n input[type='radio']{\r\n    background-color: #fff !important;\r\n    border: solid 1px #1078ba !important;\r\n    margin-right: 1.1rem;\r\n    border-radius: 100% !important;\r\n}\r\n\r\n input[type='radio']:checked{\r\n    background-color: #1078ba !important;\r\n}\r\n\r\n input::placeholder {\r\n    color: #1078ba !important;\r\n    opacity: .7 !important;\r\n}\r\n\r\n button {\r\n    border-radius: 0% !important;\r\n}\r\n\r\n#formBasicPassword {\r\n    display: flex,\r\n  }\r\n  \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
