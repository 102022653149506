import TopNavbar from "./../../components/TopNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import House from "../../assets/house.png";
import "./AreaVistoriaPage.css";
import "../../components/Loading.css";
import ItemVistoria from "../../components/ItemVistoria";
import Logo from "../../assets/logo.png";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getItens,
  createItens,
  createVistoria,
  deleteItens,
  getVencimentos,
} from "../../services/api";
import { AuthContext } from "../../contexts/auth";

function AreaVistoriaPage() {
  const { condId } = useContext(AuthContext);
  const { areaId, area_name } = useParams();

  // ESTADOS DA PÁGINA PRINCIPAL
  const [itens, setItens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [values, setValues] = useState({});
  const [nomeMorador, setNomeMorador] = useState("");
  const [numeroApartamento, setNumeroApartamento] = useState("");
  const [emailMorador, setEmailMorador] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [entrada, setEntrada] = useState(false);
  const [saida, setSaida] = useState(false);
  const [corretiva, setCorretiva] = useState(false);
  const [preventiva, setPreventiva] = useState(false);
  const [radioData, setRadioData] = useState({
    value1: "confere",
    value2: "não confere",
  });
  const [selectedFiles, setSelectedFiles] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVistoria, setSelectedVistoria] = useState(null);
  const [vencimentos, setVencimento] = useState([]);
  const [lembreteData, setLembreteData] = useState({});

  const [nextVistoriaDate, setNextVistoriaDate] = useState("");
  const [vistoriaError, setVistoriaError] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [dateError, setDateError] = useState("");

  // ------------- NOVOS ESTADOS PARA O POP-UP DE CONFIRMAÇÃO -------------
  const [showChangeVencPopup, setShowChangeVencPopup] = useState(false);
  const [itemInConfirmation, setItemInConfirmation] = useState(null);
  const [tentativeDate, setTentativeDate] = useState("");

  // ----------- FUNÇÃO PARA CALCULAR DIFERENÇA EM DIAS ENTRE DUAS DATAS --------
  function differenceInDays(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    // diferença em milissegundos
    const diffMs = d1 - d2;
    // converte para dias (arredondando para baixo)
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  // ------------- MODAL "LEMBRAR" -------------
  function handleOpenModal(item) {
    setSelectedVistoria(item);
    setSelectedDates(lembreteData[item._id] || []); // Carrega as datas já selecionadas, se houver
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
    setSelectedVistoria(null);
    setNextVistoriaDate("");
    setSelectedDates([]);
    setVistoriaError("");
    setDateError("");
  }

  function handleDateSelection(days) {
    if (selectedDates.includes(days)) {
      // Remove a data se ela já estiver selecionada
      setSelectedDates(selectedDates.filter((d) => d !== days));
    } else if (selectedDates.length < 3) {
      // Adiciona a data apenas se o limite de 3 datas não tiver sido atingido
      setSelectedDates([...selectedDates, days]);
    }
  }

  // Agrupando itens em subáreas
  const subAreasObj = itens.reduce((acc, item) => {
    if (!acc[item.subAreaName]) {
      acc[item.subAreaName] = [];
    }
    acc[item.subAreaName].push(item);
    return acc;
  }, {});

  // Criando novo item na subárea
  const handleNewItem = async (name, subAreaName) => {
    try {
      await createItens(condId, areaId, subAreaName, name);
      await loadData();
    } catch (err) {
      console.error(err);
    }
  };

  // Deletar item
  const handleDeleteItem = async (item) => {
    await deleteItens(item.condId, item.areaId, item.subAreaName, item._id);
    await loadData();
  };

  // ----------------- FUNÇÃO ESPECÍFICA PARA 'PRÓXIMA DATA' -------------------
  function handleDateChange(e, item) {
    const novaData = e.target.value;
    const key = `proxData_${item._id}`;

    // Se não houver vencimento, apenas salva direto
    if (!item.vencimento) {
      setValues({ ...values, [key]: novaData });
      return;
    }

    // Calcula diferença (novaData - vencimento)
    const diff = differenceInDays(novaData, item.data);

    // Se a diferença for maior que 30, pede confirmação
    if (diff > 30) {
      setShowChangeVencPopup(true);
      setItemInConfirmation({ item, novaData });
    } else {
      // Se não for maior que 30, atualiza normalmente
      setValues({ ...values, [key]: novaData });
    }
  }

  // -------------------- DEMAIS FUNÇÕES --------------------
  function handleInputChange(e) {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  }

  // Captura inputs radio (confere / não confere)
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    if (value === "não confere") {
      setCheckedItems([...checkedItems, name]);
      setRadioData({ ...radioData, [name]: value });
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== name));
      setRadioData({ ...radioData, [name]: value });
    }
  };

  // Captura arquivos
  const handleFileChange = (event, itemId, fileNumber) => {
    const newFile = event.target.files[0];
    setSelectedFiles({
      ...selectedFiles,
      [itemId]: {
        ...selectedFiles[itemId],
        [`file${fileNumber}`]: newFile,
        [`fileName${fileNumber}`]: newFile?.name,
      },
    });
  };

  // Checkboxes de entrada/saída/corretiva/preventiva
  const handleEntradaChange = (event) => {
    setEntrada(event.target.checked);
  };
  const handleSaidaChange = (event) => {
    setSaida(event.target.checked);
  };
  const handlePreventivaChange = (event) => {
    setPreventiva(event.target.checked);
  };
  const handleCorretivaChange = (event) => {
    setCorretiva(event.target.checked);
  };

  // Submeter vistoria
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    // Itera sobre todos os itens para anexar os arquivos correspondentes
    itens.forEach((item) => {
      const files = selectedFiles[item._id];
      if (files) {
        if (files.file1) formData.append("images", files.file1);
        if (files.file2) formData.append("images", files.file2);
      }
    });

    const itensData = itens.map((item) => {
      const itemFiles = selectedFiles[item._id];

      return {
        name: item.name,
        subAreaName: item.subAreaName,
        vencimento: values[`proxData_${item._id}`] || (() => {
          const venc = setVenc(item.name);
          if (venc) {
            const [dia, mes, ano] = venc.split("/");
            return `${ano}-${mes}-${dia}`; // Conversão para o formato ISO
          }
          return ""; // Retorna vazio caso não haja vencimento
        })(),
        quantity: values[`quantity_${item._id}`] || "",
        obs: values[`obs_${item._id}`] || "",
        confere: radioData[item._id] || "não informado",
        files: itemFiles
          ? Object.values(itemFiles)
            .filter((file) => typeof file === "string")
            .map((fileName) => fileName)
          : [],
        lembreteData: lembreteData[item._id] || item.notificarDiasAntes,
      };
    });
    ; // 👈 Teste os dados recebidos
    console.log("itensData", itensData);
    formData.append("nomeMorador", nomeMorador);
    formData.append("numeroApartamento", numeroApartamento);
    formData.append("emailMorador", emailMorador);
    formData.append("observacoes", observacoes);
    formData.append("itensData", JSON.stringify(itensData));
    formData.append("entrada", entrada);
    formData.append("saida", saida);
    formData.append("preventiva", preventiva);
    formData.append("corretiva", corretiva);

    // **Depuração: Verificar os dados antes de enviar**
    console.log("Dados a serem enviados:", {
      nomeMorador,
      numeroApartamento,
      emailMorador,
      observacoes,
      itensData,
      entrada,
      saida,
      preventiva,
      corretiva,
    });

    console.log("itens data:", itensData)
    try {
      setLoading(true);
      await createVistoria(condId, areaId, area_name, formData);
      setLoading(false);
      setAlertMessage(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  // Carrega os itens da API
  const loadData = async () => {
    try {
      setLoading(true);
      const responseItens = await getItens(condId, areaId);
      const getVenc = await getVencimentos(condId);

      console.log("📌 Dados recebidos da API (Vencimentos):", getVenc.data); // 👈 Teste os dados recebidos

      setVencimento(Array.isArray(getVenc.data.itens) ? getVenc.data.itens : []);

      setItens(responseItens.data);
      setLoading(false);
    } catch (err) {
      console.error("❌ Erro ao carregar dados:", err);
      setLoadingError(true);
    }
  };


  function setVenc(name) {
    if (!Array.isArray(vencimentos)) {
      console.error("Erro: `vencimentos` não é um array ou está indefinido.");
      return ""; // Retorna uma string vazia para evitar erro
    }

    const vencimentoItem = vencimentos.find((x) => x.name === name);

    if (vencimentoItem && vencimentoItem.vencimento) {
      let vencimentoUTC = new Date(vencimentoItem.vencimento);

      // Forçar a data para meio-dia no fuso horário UTC para evitar erro na conversão
      vencimentoUTC.setUTCHours(12, 0, 0, 0);

      const vencimentoCorrigido = new Date(vencimentoUTC.getTime());
      return vencimentoCorrigido.toLocaleDateString("pt-BR");
    }

    return ""; // Retorna string vazia caso não encontre
  }




  useEffect(() => {
    (async () => await loadData())();
  }, []);

  if (loadingError) {
    return (
      <div className="loading">
        Erro ao carregar itens da vistoria.{" "}
        <Link to={`/${condId}/`}>Voltar</Link>
      </div>
    );
  }

  if (loading) {
    return <div className="loading">carregando...</div>;
  }

  return (
    <div className="user-menu">
      <TopNavbar />
      <div className="area">
        <div className="container px-4 py-5" id="hanging-icons">
          <div className="row">
            <div className=" col text-center top-item">
              <div className="circle">
                <img className="item-icon" src={House} alt="icon" />
              </div>
            </div>
            <div className="text-center font-bold text-xl mb-4">
              <h3>{area_name}</h3>
            </div>
          </div>

          <div className="container">
            {/* Componente para adicionar novo item na subárea */}
            <ItemVistoria onNewItem={handleNewItem} />
          </div>

          {/* FORM PRINCIPAL */}
          <form onSubmit={handleFormSubmit}>
            <div className="container">
              {Object.entries(subAreasObj)
                .map(([subAreaName, subAreaItens]) => (
                  <div key={subAreaName}>
                    <h2 className="text-2xl uppercase border-b font-bold pb-2 mt-5 ">
                      {subAreaName}
                    </h2>
                    <div className="row">
                      {subAreaItens.map((item) => (
                        <div
                          className={`col p-4 m-3 item d-flex flex-column ${checkedItems.includes(item._id)
                            ? "border-danger"
                            : ""
                            }`}
                          id={item.name}
                          key={item._id}
                        >
                          <button
                            className="delete-item"
                            type="button"
                            onClick={() => handleDeleteItem(item)}
                          >
                            X
                          </button>
                          <h4 className="text-xl font-bold uppercase">
                            {item.name}
                          </h4>

                          {/* ---------------- VENCIMENTO ou PEÇAS ---------------- */}
                          <div className="flex items-center gap-2 py-2">
                            <span className="bg-[#f8f9fa] p-2 rounded-md border">
                              {area_name.toLowerCase() === "manutenção predial" ? "Vencimento" : "Peças"}
                            </span>

                            {area_name.toLowerCase() === "manutenção predial" ? (
                              <span className="text-sm text-zinc-700">
                                {(() => {
                                  const venc = setVenc(item.name);
                                  return venc.length > 0 ? venc : "não possui data de vencimento";
                                })()}
                              </span>
                            ) : (
                              <input
                                type="number"
                                className="w-full border border-gray-300 rounded px-2 py-1"
                                name={`quantity_${item._id}`}
                                value={values[`quantity_${item._id}`] || ""}
                                onChange={handleInputChange}
                              />
                            )}

                          </div>


                          {/* ---------------- PRÓXIMA DATA ---------------- */}
                          {area_name.toLowerCase() === "manutenção predial" && (
                            <div className="input-group py-2">
                              <span className="input-group-text">
                                Próxima data
                              </span>
                              <input
                                type="date"
                                className="form-control"
                                id={`proxData_${item._id}`}
                                name={`proxData_${item._id}`}
                                value={values[`proxData_${item._id}`] || ""}
                                onChange={(e) => handleDateChange(e, item)}
                              />
                            </div>
                          )}

                          {/* ---------------- RADIO: Confere / Não confere ---------------- */}
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={item._id}
                              id={`confere${item._id}`}
                              value={radioData.value1}
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`confere${item._id}`}
                            >
                              Confere
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={item._id}
                              id={`nao_confere${item._id}`}
                              value={radioData.value2}
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`nao_confere${item._id}`}
                            >
                              Não confere
                            </label>
                          </div>

                          {/* ---------------- OBSERVAÇÃO ---------------- */}
                          <div className="py-2">
                            <label
                              htmlFor={`obs_${item._id}`}
                              className="form-label"
                            >
                              Observação:
                            </label>
                            <textarea
                              className="form-control d-inline"
                              id={`obs_${item._id}`}
                              placeholder="observações..."
                              rows="3"
                              name={`obs_${item._id}`}
                              value={values[`obs_${item._id}`] || ""}
                              onChange={handleInputChange}
                            ></textarea>
                          </div>

                          {/* ---------------- UPLOAD DE ARQUIVOS ---------------- */}
                          <div className="py-2">
                            <div className="mb-3">
                              <label
                                htmlFor={`formFile_${item._id}`}
                                className="form-label"
                              >
                                Escolha o arquivo 1
                              </label>
                              <input
                                className="form-control"
                                name="images"
                                type="file"
                                id={`formFile_${item._id}_1`}
                                accept="image/*,video/*"
                                onChange={(event) =>
                                  handleFileChange(event, item._id, 1)
                                }
                              />

                              <label
                                htmlFor={`formFile2_${item._id}`}
                                className="form-label"
                              >
                                Escolha o arquivo 2
                              </label>
                              <input
                                className="form-control"
                                name="images"
                                type="file"
                                id={`formFile2_${item._id}_2`}
                                accept="image/*,video/*"
                                onChange={(event) =>
                                  handleFileChange(event, item._id, 2)
                                }
                              />
                            </div>
                          </div>

                          {/* ---------------- BOTÃO "LEMBRAR" (Somente Manutenção Predial) ---------------- */}
                          {area_name.toLowerCase() === "manutenção predial" && (
                            <div className="w-full flex justify-center items-center py-4">
                              <button
                                className="w-full text-bold p-2 text-white bg-blue-600"
                                onClick={() => handleOpenModal(item)}
                                type="button"
                              >
                                Lembrar
                              </button>
                            </div>
                          )}

                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              {/* FORM DE ENVIO GERAL */}
              <div className="container">
                <div className="row">
                  <div className="col form-vistoria">
                    <div className="mb-3 pt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nome do morador"
                        value={nomeMorador}
                        onChange={(e) => setNomeMorador(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 pt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Número do apartamento e bloco ex: 123-A"
                        value={numeroApartamento}
                        onChange={(e) => setNumeroApartamento(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 pb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email do morador"
                        value={emailMorador}
                        onChange={(e) => setEmailMorador(e.target.value)}
                      />
                    </div>

                    <div className="box-checkbox">
                      <div className="box-title">VISTORIA</div>
                      <div className="box-container">
                        <div className="a-checkbox">
                          <label className="container-checkbox">
                            Entrada
                            <input
                              onChange={handleEntradaChange}
                              type="checkbox"
                            />
                            <span className="checkmark"></span>
                          </label>

                          <label className="container-checkbox">
                            Saída
                            <input
                              onChange={handleSaidaChange}
                              type="checkbox"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="a-checkbox">
                          <label className="container-checkbox">
                            Corretiva
                            <input
                              onChange={handleCorretivaChange}
                              type="checkbox"
                            />
                            <span className="checkmark"></span>
                          </label>

                          <label className="container-checkbox">
                            Preventiva
                            <input
                              onChange={handlePreventivaChange}
                              type="checkbox"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 pb-3">
                      <textarea
                        className="form-control"
                        rows="3"
                        placeholder="observações..."
                        value={observacoes}
                        onChange={(e) => setObservacoes(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="d-grid gap-2 pb-3">
                      <button className="btn btn-primary" type="submit">
                        Enviar
                      </button>
                    </div>
                  </div>
                  <div className="col form-vistoria-image">
                    <img className="vistoria-image" src={Logo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* ALERT DE CONFIRMAÇÃO DE SUCESSO */}
      <Alert
        show={alertMessage}
        variant="success"
        className="custom-alert"
      >
        <Alert.Heading className="alert-title">
          Vistoria Realizada com sucesso!
        </Alert.Heading>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              window.location.replace(`/${condId}`);
            }}
            variant="outline-success"
          >
            ok!
          </Button>
        </div>
      </Alert>

      {/* MODAL DE NOTIFICAÇÃO - SÓ EXIBE SE area_name === "Manutenção Predial" */}
      {isModalOpen && selectedVistoria && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96">
            <h3 className="text-xl font-bold mb-4">
              Escolher notificações para: {selectedVistoria.name}
            </h3>
            <p className="mb-4">Escolha até 3 datas para notificação:</p>
            <div className="grid grid-cols-2 gap-2">
              {[60, 45, 30, 20, 10, 1, 0].map((days) => (
                <button
                  key={days}
                  className={`px-4 py-2 rounded-md ${selectedDates.includes(days)
                    ? "bg-green-600 text-white"
                    : "bg-gray-300 text-gray-800"
                    }`}
                  onClick={() => handleDateSelection(days)}
                >
                  {days === 0 ? "No Dia" : `${days} Dias`}
                </button>
              ))}
            </div>
            <div className="mt-4 flex justify-between">
              <button
                className="px-4 bg-blue-500 text-white hover:bg-opacity-50 py-2 rounded-md"
                onClick={() => {
                  // Atualiza lembreteData com as datas selecionadas para o item atual
                  setLembreteData({
                    ...lembreteData,
                    [selectedVistoria._id]: selectedDates,
                  });
                  closeModal();
                }}
              >
                Confirmar
              </button>
              <button className="text-gray-500 underline" onClick={closeModal}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ------------------ POP-UP DE CONFIRMAÇÃO DE +30 DIAS ------------------ */}
      {showChangeVencPopup && itemInConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96">
            <h3 className="text-xl font-bold mb-4">
              Item já selecionado, com vencimento em{" "}
              <span className="text-red-500">
                {itemInConfirmation.item.vencimento}
              </span>
              . Deseja alterar?
            </h3>
            <div className="mt-4 flex justify-between">
              <button
                className="px-4 py-2 rounded-md bg-blue-600 text-white"
                onClick={() => {
                  const { item, novaData } = itemInConfirmation;
                  const key = `proxData_${item._id}`;

                  setValues({
                    ...values,
                    [key]: novaData,
                  });
                  setShowChangeVencPopup(false);
                  setItemInConfirmation(null);
                }}
              >
                Sim
              </button>
              <button
                className="px-4 py-2 rounded-md bg-gray-400 text-white"
                onClick={() => {
                  // Cancela a alteração
                  setShowChangeVencPopup(false);
                  setItemInConfirmation(null);
                  setTentativeDate("");
                }}
              >
                Não
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AreaVistoriaPage;
