import React, { useState, useEffect, useContext } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { AuthContext } from "../../contexts/auth"; // Ajuste se necessário
import { getAreas } from "../../services/api";     // Ajuste se necessário

const Aside = ({ filters, setFilters, applyFilters, clearFilters }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { condId } = useContext(AuthContext);

  // Armazena as áreas retornadas pela API
  const [areas, setAreas] = useState([]);

  // Subcategorias locais de "Manutenção Predial"
  const maintenanceSubcategories = ["Itens Vistoriados"];

  useEffect(() => {
    const loadAreas = async () => {
      try {
        const response = await getAreas(condId);
        console.log("Áreas recebidas da API:", response.data); // <-- Verifica se "Manutenção Predial" está na resposta
        setAreas(response.data);
      } catch (error) {
        console.error("Erro ao buscar áreas:", error);
      }
    };
  
    if (condId) {
      loadAreas();
    }
  }, [condId]);
    

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const handleFilterSelect = (category, subcategory = null) => {
    setFilters((prev) => ({
      ...prev,
      category: category.trim() || "",
      subcategory: subcategory || "",
    }));

    console.log("Categoria:", category, "Subcategoria:", subcategory);
    // Removido o trecho de "Itens a Vencer" para não navegar mais para /itens-a-vencer
  };

  // Filtra todas as áreas que NÃO são "Manutenção Predial"
  const normalAreas = areas.filter((area) => area.name !== "Manutenção Predial");

  // Verifica se existe "Manutenção Predial" na lista retornada
  const hasMaintenanceArea = areas.some(
    (area) => area.name === "Manutenção Predial"
  );

  return (
    <aside
      className={`transition-all duration-300 flex flex-col flex-shrink-0 ${
        isExpanded ? "w-64" : "w-16"
      } h-screen box-border shadow-lg overflow-y-auto`}
    >
      <button
        onClick={toggleSidebar}
        className="rounded-md px-3 mt-10 py-2 flex items-center justify-center"
      >
        {isExpanded ? (
          <FiChevronLeft className="text-black hover:text-white hover:bg-blue-600 text-3xl p-2 rounded-full" />
        ) : (
          <FiChevronRight className="text-black hover:text-white hover:bg-blue-600 text-3xl p-2 rounded-full" />
        )}
      </button>

      {isExpanded && (
        <div className="mb-2 flex flex-col p-4">
          {/* Primeiro bloco: áreas "comuns" */}
          <h3 className="text-lg mb-4 font-semibold text-gray-700 border-b pb-2">
            Áreas
          </h3>
          {normalAreas.map((area) => (
            <div
              key={area.id} // Ajustar caso a propriedade seja diferente
              className={`p-3 mb-2 rounded-md shadow-sm transition duration-300 cursor-pointer ${
                filters.category === area.name
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 hover:bg-blue-400"
              }`}
              onClick={() => handleFilterSelect(area.name)}
            >
              {area.name}
            </div>
          ))}

          {/* Segundo bloco: somente se existir "Manutenção Predial" na API */}
          {hasMaintenanceArea && (
            <>
              <h3 className="text-lg mt-6 mb-4 font-semibold text-gray-700 border-b pb-2">
                Manutenção Predial
              </h3>
              <div className="mt-2">
                {maintenanceSubcategories.map((subCategory) => (
                  <div
                    key={subCategory}
                    className={`p-3 mb-2 rounded-md shadow-sm transition duration-300 cursor-pointer ${
                      filters.subcategory === subCategory
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 hover:bg-blue-400"
                    }`}
                    onClick={() =>
                      handleFilterSelect("Manutenção Predial")
                    }
                  >
                    {subCategory}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </aside>
  );
};

export default Aside;
