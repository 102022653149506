import { useEffect } from "react";
import "./paginations.css";

const Pagination = ({ total, setSelected, selected, numberForPage }) => {
    const items = [];

    const totalNumber = Math.ceil(
        parseFloat(total) / parseFloat(numberForPage)
    );

    console.log(numberForPage);

    for (let i = 1; i <= totalNumber; i++) {
        items.push(i);
    }

    function handleClickNext() {
        if (parseInt(selected) + 1 <= items.length) {
            setSelected(String(parseInt(selected) + 1));
        }

        return;
    }

    function handleClickPrevious() {
        if (parseInt(selected) - 1 >= items[0]) {
            setSelected(String(parseInt(selected) - 1));
        }

        return;
    }

    return (
        <>
            <div className="container-pagination">
                <div className="box-pagination">
                    <div
                        onClick={handleClickPrevious}
                        className="item-pagination previous"
                    >
                        <p>&lt;</p>
                    </div>

                    {items.map((item) => {
                        return (
                            <div
                                onClick={() => setSelected(item.toString())}
                                className={`item-pagination ${
                                    selected === item.toString()
                                        ? "selected"
                                        : ""
                                }`}
                                key={item}
                            >
                                <p>{item}</p>
                            </div>
                        );
                    })}

                    <div
                        onClick={handleClickNext}
                        className="item-pagination next"
                    >
                        <p>&gt;</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pagination;
