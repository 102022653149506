// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anterior-vist {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    border: 1px solid black;
    margin-bottom: 10px;
}

.btn {
    margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/vistorias/vistoriasPage.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".anterior-vist {\r\n    display: flex !important;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    border: 1px solid black;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.btn {\r\n    margin: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
