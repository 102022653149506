// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.item-icon {
    height: 7rem;
}

.area-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    margin-bottom: 10px;
}

.circle {
    border: 2px solid #1078ba;
    padding: 1.rem;
    border-radius: 100%;
}

span {
    color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ItemMenu.css"],"names":[],"mappings":";AACA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["\r\n.item-icon {\r\n    height: 7rem;\r\n}\r\n\r\n.area-item {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    border: none;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.circle {\r\n    border: 2px solid #1078ba;\r\n    padding: 1.rem;\r\n    border-radius: 100%;\r\n}\r\n\r\nspan {\r\n    color: black !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
